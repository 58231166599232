import axios from "axios";

export let headers = {
  "Tenant-Access-Token": "Yjg4ZTdhZDVmZTMzMjEyMTI2YzZhZTdmYWM4ZGQwMTg=",
  "Tenant-Access-Key": "AtNiV5S8zsAyQg",
};

export const api = axios.create({
  baseURL: "https://tiltlabs-dev.confirmu.com",
  headers: {
    "Tenant-Access-Token": "Yjg4ZTdhZDVmZTMzMjEyMTI2YzZhZTdmYWM4ZGQwMTg=",
    "Tenant-Access-Key": "AtNiV5S8zsAyQg",
  },
});

export const BASE_URL = "https://tiltlabs-dev.confirmu.com";
