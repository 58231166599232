import React, { useState } from "react";
import { intialLoading, setIndex } from "../../store/screentransistion/actions";
import { saveUserDetails } from "../../store/trackinguser/actions";
import Layout from "../common/layoutred";
// import Button from "../../assets/images/backgrounds/submit_button.png";
import { fetchShuffle } from "../../store/shuffledata/operations";
import { Alert } from "@material-ui/lab";
import { isAndroid } from "react-device-detect";
import "./style.scss";
import fullScreen from "../../utils/fullScreen";
import { connect } from "react-redux";
import {
  toggleMusic,
  toggleVoiceOver,
} from "../../store/screentransistion/actions";
import music from "../../assets/audio/BGMusic.mp3";
import PlayMusic from "../../utils/playMusic";
import StartButton from "../../assets/images/anil_revamp_sets/start_button.png";
import Select from "react-select";
//GOOGLE ANALYTICS
window.gtag("event", "REGISTRATION", {
  event_category: "REGISTRATION",
  event_label: "REGISTRATION",
});

const Registration = ({
  fetchShuffle,
  languages,
  zones,
  saveUserDetails,
  intialLoading,
  setIndex,
  toggleMusic,
  toggleVoiceOver,
}) => {
  const [india] = zones;
  const [english] = languages;

  const [data, setData] = useState({
    zone: india._id,
    lang: english.value,
    number: "",
    name: "",
  });

  const activate = fullScreen();

  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("Please enter valid details");

  const handleChange = (event) => {
    const { name, value } = event.target;

    const rExp = /^[0-9\b]+$/;

    if (name === "number") {
      if (rExp.test(event.target.value)) {
        if (event.target.value.length <= 10) {
          setData({ ...data, [name]: value });
          setError(false);
        } else {
          setError(true);
          setMsg("Maximum 10 numbers allowed");
          return;
        }
      } else {
        setData({ ...data, [name]: "" });
        setError(true);
        setMsg("Please enter a number");
        return;
      }
    }

    setData({ ...data, [name]: value });
  };

  const register = (event) => {
    event.preventDefault();
    if (data.number.length !== 10) {
      setError(true);
      setMsg("Please enter valid number");
      return;
    }
    // if (!data.name) {
    //   setError(true);
    //   setMsg("Please enter your name");
    //   return;
    // }
    if (data.number.length === 10 && data.lang && data.zone) {
      fetchShuffle(
        [data.lang, data.number],
        () => {
          // This is done so that audio controls are initiated as part of a user action
          toggleVoiceOver(true);
          toggleMusic(true);
          const audioRef = PlayMusic(music);
          audioRef.pause();

          intialLoading();
          setIndex(0);
          saveUserDetails(data);
          if (isAndroid) {
            activate();
          }
        },
        (err) => {
          setError(true);
          setMsg("NetWork Failed Please Check Network Connection");
        }
      );
    } else {
      setError(true);
      setMsg("All fields are required");
    }
  };

  const [selectedOptions, setSelectedOptions] = useState(
    languages.map((lan) => {
      return { value: lan.value, label: lan.language };
    })
  );
  const [selectedOption, setSelectedOption] = useState(selectedOptions[0]);

  return (
    <div className="registration__container">
      {/* <img src={StartBg} alt="start_background" className="start_bg"/> */}
      <form onSubmit={register}>
        {/* <div className="form__control">
          <input
            type="text"
            placeholder="Enter Your Name"
            name="name"
            value={data.name}
            onChange={handleChange}
          />
        </div> */}
        <div className="form__control">
          <input
            type="number"
            placeholder="Enter Phone Number"
            name="number"
            value={data.number}
            onChange={handleChange}
          />
        </div>
        <div className="form__control">
          <Select
            className="languages"
            options={selectedOptions}
            defaultValue={selectedOption}
            isSearchable={false}
            value={selectedOption} // Set the value using the state
            onChange={(selected) => {
              setSelectedOption(selected); // Update the selected option in the state

              setData({ ...data, lang: selected.value }); // Update the data with the selected language value
            }}
          />
          {/* <select name="lang" value={data.lang} onChange={handleChange}>
            {languages
              ? languages.map((lan) => (
                  <option key={lan.value} value={lan.value}>
                    {lan.language}
                  </option>
                ))
              : null}
          </select> */}
        </div>
        {/* <div className="form__control">
            <select name="country" value={data.country} onChange={handleChange}>
              {zones
                ? zones.map((zone) => (
                    <option key={zone._id} value={zone._id}>
                      {zone.zoneName}
                    </option>
                  ))
                : null}
            </select>
          </div> */}

        <div type="submit" className=" start_button" onClick={register}>
          <img src={StartButton} alt="start_button" className="start_next" />
        </div>
        <div className="form__control error__msg">
          {error ? (
            <Alert severity="error">
              <h3>{msg}</h3>
            </Alert>
          ) : null}
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    shuffleData: state.shuffleData,
    progress: state.progress,
    languages: state.intialize.languages,
    zones: state.intialize.zones,
    audio: state.shuffleData,
    language: state.trackingUser.userDeatils,
  };
};

const mapDispathToProps = (dispatch) => {
  return {
    fetchShuffle: (language, success, failure) =>
      dispatch(fetchShuffle(language, success, failure)),
    saveUserDetails: (details) => dispatch(saveUserDetails(details)),
    intialLoading: () => dispatch(intialLoading()),
    setIndex: (index) => dispatch(setIndex(index)),
    toggleMusic: (toggle) => dispatch(toggleMusic(toggle)),
    toggleVoiceOver: (toggle) => dispatch(toggleVoiceOver(toggle)),
  };
};

export default connect(mapStateToProps, mapDispathToProps)(Registration);
